import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[disableAutoComplete]'
})
export class DisableAutoCompleteDirective {

  constructor() { }

  @HostListener('focus')
  onFocus() {
    this.disableAutoComplete();
  }

  private disableAutoComplete() {
    const input = <HTMLInputElement>document.activeElement;
    input.setAttribute('autocomplete', 'off');
    input.blur();
  }

}